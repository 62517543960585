<template>
	<main class="container">
		<hotel-nav />
		<h1 class="home-heading mb-0">
			Find special deals on hotels, hotel apartments, resorts and much more...
		</h1>
		<p class="home-text mb-5">
			From cozy country homes to funky city apartments
		</p>
		<!-- All inputs are enclosed into input-container -->
		<div class="input-container">
			<div class="ejs_input">
				<ejs-autocomplete v-model="location"
					style="padding-left: 50px; background-image: url(https://cf.bstatic.com/static/img/cross_product_index/accommodation/07ca5cacc9d77a7b50ca3c424ecd606114d9be75.svg); background-repeat: no-repeat; background-position-x: 15px; background-position-y: center; background-color: white; min-height: 39px;"
					type="search" placeholder="Where are you going?" :dataSource='uniqueNames'></ejs-autocomplete>
			</div>

			<div class="date-input-container">
				<el-date-picker ref="bc" v-model="date_range" type="daterange" value-format="yyyy-MM-dd" range-separator="-"
					start-placeholder="Checkin Date" end-placeholder="Checkout Date">
				</el-date-picker>
			</div>
			<!-- Date inputs ends here-->

			<!-- Toggling guest input number selection when mouse enters and leaves the input area-->
			<div class="guest-input" @mouseenter="isSelectionOpen = true" @mouseleave="isSelectionOpen = false">
				<div class="guest-input-header">
					<span class="me-2">{{ adultNum }} Adults</span><span class="me-2">-</span><span class="me-2">{{ childrenNum }}
						Children</span><span class="me-2">-</span>
					<span>{{ roomNum }} Room</span>
				</div>
				<div v-show="isSelectionOpen" class="guest-input-body w-100">
					<div class="d-flex align-items-center w-100 mb-3">
						<span class="w-50">Adult</span>
						<div class="d-flex align-items-center w-50">
							<button @click="adultNum === 1 ? null : adultNum--" class="guest-btn">
								-</button><span class="count-span ms-3 me-3">{{ adultNum }}</span><button
								@click="adultNum === 20 ? null : adultNum++" class="guest-btn">
								+
							</button>
						</div>
					</div>
					<div class="d-flex align-items-center w-100 mb-3">
						<span class="w-50">Children (Age 8-17)</span>
						<div class="d-flex align-items-center w-50">
							<button @click="childrenNum === 0 ? null : childrenNum--" class="guest-btn">
								-</button><span class="count-span ms-3 me-3">{{ childrenNum }}</span><button
								@click="childrenNum === 20 ? null : childrenNum++" class="guest-btn">
								+
							</button>
						</div>
					</div>
					<div class="d-flex align-items-center w-100">
						<span class="w-50">Room</span>
						<div class="d-flex align-items-center w-50">
							<button @click="roomNum === 1 ? null : roomNum--" class="guest-btn">
								-</button><span class="count-span ms-3 me-3">{{ roomNum }}</span><button
								@click="roomNum === 20 ? null : roomNum++" class="guest-btn">
								+
							</button>
						</div>
					</div>
				</div>
			</div>
			<!--Accomodation information is sent to parent(App) component and goes to HotelResults route-->
			<el-button icon="el-icon-search" class="search-button" @click="setGuestData">Search</el-button>
		</div>

		<!-- Some city photos and names for decoration -->
		<h2>Destination Ideas</h2>
		<div class="destination-ideas">
			<div class="destination1">
				<p class="dest-name">Dubai <img
						src="https://cf.bstatic.com/static/img/flags/24/ae/e5f54d8e1da0853393141831bcb0104381215a7e.png"
						alt="United Arab Emirates" valign="middle"></p>
			</div>
			<div class="destination2">
				<p class="dest-name">Abu Dhabi <img
						src="https://cf.bstatic.com/static/img/flags/24/ae/e5f54d8e1da0853393141831bcb0104381215a7e.png"
						alt="United Arab Emirates" valign="middle"></p>
			</div>
			<div class="destination3">
				<p class="dest-name">Sharjah <img
						src="https://cf.bstatic.com/static/img/flags/24/ae/e5f54d8e1da0853393141831bcb0104381215a7e.png"
						alt="United Arab Emirates" valign="middle"></p>
			</div>
		</div>
		<br>
		<br>
		<br>
		<br>

		<div class="footer">
			<div class="container">
				<div class="row pt-5 pb-5">
					<div class="col-md-4 col-xs-12 right-border">
						<h5>INFORMATION</h5>
						<div class="list">
							<ul class="list-unstyled">
								<li>
									<router-link class="link" to="/about">About Us</router-link>
								</li>
								<li>
									<router-link class="link" to="/privacy">Privacy Policy</router-link>
								</li>
								<li>
									<router-link class="link" to="/terms">Terms & Conditions</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-4 col-xs-12 right-border">
						<h5>CUSTOMER SUPPORT</h5>
						<div class="list">
							<ul class="list-unstyled">
								<li>
									<router-link class="link" to="/">FAQ</router-link>
								</li>
								<li>
									<router-link class="link" to="/">Payment</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-4 col-xs-12 location">
						<h5>Location</h5>
						<div>Office 901, Ontario Tower, Bussiness Bay, Burj Khalifa District, Dubai, UAE</div>
					</div>
				</div>
				<div class="row copyright">
					<div class="col-xs-12 col-md-12">
						<p>© 2021 DESERT LINK TOURISM LLC</p>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>

import { AutoCompletePlugin } from "@syncfusion/ej2-vue-dropdowns";
import Vue from "vue";
import axios from "axios";
import HotelNav from '../components/HotelNav.vue';
import { $bus } from "@/eventBus.js";
import HotelResults from './HotelResults.vue'
Vue.use(AutoCompletePlugin);

export default {
	name: "Home",
	components: {
		HotelNav,
		HotelResults
		//   AdvancedSearch
	},
	data() {
		return {
			waterMark: 'e.g. Jeddah',
			//sportsData: data['sportsData'],
			isSelectionOpen: false,
			location: "Dubai",
			uniqueNames: [],
			date_range: null,
			checkinDate: null,
			checkoutDate: null,
			adultNum: 1,
			childrenNum: 0,
			roomNum: 1,
			selected: null,
			items: [],
			item: []
		};
	},
	mounted() {
		this.getLocations();

	},
	methods: {
		//When inputs are filled by the guest and search button clicked, emits setGuestData event and sends data to parent(App) component
		async setGuestData() {
			let today = new Date().toISOString().slice(0, 10);
			let vm = this
			if (this.date_range != null && Date.parse(this.date_range[0]) >= Date.parse(today)) {
				let obj = {
					location: vm.location,
					adult: vm.adultNum,
					children: vm.childrenNum,
					room: vm.roomNum,
					days: vm.calculateDays,
					checkinDate: vm.date_range[0],
					checkoutDate: vm.date_range[1],
				}
				$bus.$emit('updateGuestData', obj)
				this.$router.push('/hotels');
			}
			else {
				this.$message({
					type: 'warning',
					message: 'Enter Check-in and Check-out dates'
				});
				this.$refs.bc.focus();
			}
			//getProductById(location);
		},
		async getLocations() {
			let vm = this
			try {
				
				await axios.get(
					`https://www.desertlinktours.com/api/hotels/HotelLocation`
				).then((response) => {
					vm.item = response.data;
					for (let i = 0; i <= vm.item.length; i++) {
						vm.items.push(vm.item[i]["HotelName"], vm.item[i]["City"], vm.item[i]["Country"]);
						vm.uniqueNames = vm.getUnique(vm.items);
					}
				}).catch((error) => console.debug(error))

				

			} catch (err) {
				console.log(err);
			}
		},
		async getProductById(location) {
			try {
				const response = await axios.get(
					`https://www.desertlinktours.com/api/hotels/HotelLocation/${location}`
				);
				this.items=response.data;
				//this.PckgId = response.data.PckgId;
			} catch (err) {
				console.log(err);
			}
		},
		// Getting current date and giving it as initial value to checkinDate
		today() {
			let today = new Date();
			today = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate().length === 2 ? today.getDate() : + today.getDate()
				}`;
			this.checkinDate = today;
		},
		//Getting tomorrow date and giving it as initial value to checkoutDate
		tomorrow() {
			let today = Date.now();
			let tomorrow = new Date(today + 86400000);
			tomorrow = `${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1}-${tomorrow.getDate().length === 2
					? tomorrow.getDate()
					: + tomorrow.getDate()
				}`;
			this.checkoutDate = tomorrow;
		},
		getUnique(array) {
			var uniqueArray = [];
			// Loop through array values
			for (let i = 0; i < array.length; i++) {
				if (uniqueArray.indexOf(array[i]) === -1) {
					uniqueArray.push(array[i]);
				}
			}
			return uniqueArray;
		},
	},
	computed: {
		//Calculates the accommodation days based on checkin and checkout dates
		calculateDays() {
			let checkin = new Date(this.date_range[0]);
			let checkout = new Date(this.date_range[1]);
			if (checkin >= checkout) {
				this.today();
				this.tomorrow();
				return 1;
			} else {
				let difference = checkout.getTime() - checkin.getTime();
				return difference / (1000 * 3600 * 24);
			}
		},
	},
};
</script>

<style scoped>
.home {
	min-height: 100vh;
	max-width: 100%;
	/* padding: 130px 60px 30px 60px; */
	background-color: #f5f5f5;
	color: rgb(51, 51, 51);
}

.footer {
	border-top: 1px solid #FFC107;
}

.home-heading {
	font-size: 24px;
	font-weight: 600;
}

.home-text {
	font-size: 14px;
	font-weight: 400;
}

.ejs_input {
	grid-column-start: 1;
	grid-column-end: 4;
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	border-right: 2px solid #FFC107;
}

.input-container {
	width: 100%;
	border: 2px solid #FFC107;
	border-radius: 5px;
	display: grid;
	margin-bottom: 50px;
}

.location-input,
.date-input-container {
	height: 52px;
	outline: none;
	border: none;
	padding-left: 50px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.location-input {
	grid-column-start: 1;
	grid-column-end: 2;
	background-image: url("https://cf.bstatic.com/static/img/cross_product_index/accommodation/07ca5cacc9d77a7b50ca3c424ecd606114d9be75.svg");
	background-repeat: no-repeat;
	background-position-x: 15px;
	background-position-y: center;
	border-right: 2px solid #FFC107;

}

.date-input-container {
	font-size: 16px;
	position: relative;
	grid-column-start: 4;
	grid-column-end: 5;
	background-color: white;
	background-image: url("https://cdn2.iconfinder.com/data/icons/web/512/Calendar-512.png");
	background-size: 20px;
	background-repeat: no-repeat;
	background-position-x: 15px;
	background-position-y: center;
	border-right: 2px solid #FFC107;
	cursor: pointer;
}

.datepicker-input {
	position: absolute;
	left: 0;
	top: 0;
	width: 50%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
	box-sizing: border-box;
}

.datepicker-input::-webkit-calendar-picker-indicator {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	cursor: pointer;
}

.checkout {
	position: absolute;
	left: 50%;
	top: 0;
	width: 50%;
}

.guest-input {
	position: relative;
	height: 52px;
	grid-column-start: 5;
	grid-column-end: 7;
	padding-left: 45px;
	background-color: white;
	background-image: url(//cf.bstatic.com/static/img/cross_product_index/guest/b2e5f2aa32b71ca0fc66aa671e4e958bcd69b7d0.svg);
	background-size: 16px;
	background-repeat: no-repeat;
	background-position-x: 15px;
	background-position-y: center;
	display: flex;
	align-items: center;
	cursor: pointer;
	border-right: 2px solid #FFC107;
}

.guest-input-body {
	padding: 20px;
	background-color: white;
	position: absolute;
	top: 52px;
	left: 0;
}

.guest-btn {
	width: 40px;
	height: 40px;
	background-color: white;
	color: #005999;
	border: 1px solid #005999;
	border-radius: 3px;
}

.guest-btn:hover {
	color: white;
}

.search-button {
	grid-column-start: 7;
	grid-column-end: 8;
}

.count-span {
	width: 20%;
	text-align: center;
}

button {
	font-size: 20px;
	font-weight: 500;
	border: none;
	outline: none;
	color: white;
	background-color: black;
	height: 100%;
	width: 100%;
}

button:hover {
	background-color: #E4A700;
}

h2 {
	font-size: 24px;
	font-weight: 600;
	color: black;
}

.destination-ideas {
	width: 100%;
	min-height: 250px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 20px;
}

.destination1 {
	background: url(https://cf.bstatic.com/xdata/images/city/540x270/619923.webp?k=4fb13225390240a51ee5aa1d76318d03dc0de8a046ddc06e4598f17b287bdcc9&o=) no-repeat center center;
	background-size: cover;
}

.destination2 {
	background: url(https://cf.bstatic.com/xdata/images/city/540x270/349028.webp?k=3a1163fced4ff96ee361d90a70ff20e66cbb37cfd3414d5f79f6f2a24881d420&o=) no-repeat center center;
	background-size: cover;
}

.destination3 {
	background: url(https://cf.bstatic.com/xdata/images/city/540x270/654877.webp?k=bc70ebd827a40c515c962e565fbfa01b8621035ffd7eb024789a7fb171a0d292&o=) no-repeat center center;
	background-size: cover;
}

.dest-name {
	background: linear-gradient(0deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(38, 38, 38, 1) 100%);
	padding: 10px 20px;
	font-size: 30px;
	font-weight: 600;
	color: rgb(241, 241, 241);
	text-shadow: 1px 1px #030303;
}

.error {
	color: red;
}

@media only screen and (max-width: 1000px) {
	.input-container {
		grid-template-columns: 1fr;
	}

	.location-input,
	.date-input-container,
	.guest-input {
		grid-column-start: 1;
		grid-column-end: 2;
		border-right: none;
		border-bottom: 2px solid #FFC107;
	}

	.search-button {
		grid-column-start: 1;
		grid-column-end: 2;
		height: 52px;
	}
}

@media only screen and (max-width: 800px) {
	.destination-ideas {
		grid-template-columns: 1fr;
		gap: 20px;
	}

	.destination1,
	.destination2,
	.destination3 {
		height: 250px;
	}
}

@import url(https://cdn.syncfusion.com/ej2/material.css);

h5 {
	text-align: center;
	font-weight: 500;
	font-family: "Open Sans", sans-serif;
}

.list-unstyled {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.right-border {
	border-right: 1px solid #FFC107;
	color: #212121;
}

.link {
	cursor: pointer;
	transition: all 500ms ease-in-out;
	color: #212121;
	text-decoration: none;
}

.link:hover {
	font-weight: 600;
	transition: all 500ms ease-in-out;
	color: #26A69A;
}

.copyright {
	text-align: center;
	padding: 20px 0;
	border-top: 1px solid #FFC107;
	border-left: 1px solid #FFC107;

}

.location {
	text-align: center;
}

h5 {
	text-transform: uppercase;
}
</style>
