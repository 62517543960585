<template>
   <hotels 
      @setGuestData="getGuestData"
      @sendGuestInfo="getGuestInfo"
      :hotelsData="data"
      :guestData="guestData"
      :allGuestInfo="allGuestInfo"
   />
</template>
<script>
import Hotels from './Hotels.vue'
export default {
   data() {
      return {
         data: [0],
         guestData: null,
         allGuestInfo: null,
      }
   },

   components: {Hotels},

   methods: {
      getGuestData(val) {
         this.guestData = val;
      },
      getGuestInfo(val) {
         this.allGuestInfo = val;
      },
   }
}
</script>
<style lang="scss" scoped>

</style>