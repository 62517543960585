<template>
  <nav class="navbar navbar-expand-lg navbar-dark" v-if="isLogged && user.isAdmin">
    <div class="container-fluid">
      <!--Clickable company logo routing to the home page-->
      <router-link class="navbar-brand me-5" :to="{ name: 'home' }">
        <img class="logo" src="../assets/logo.svg"/></router-link>
      
      <!--Navigation links all routing to the home page-->
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mb-2 mb-lg-0" style="margin-left: 50px;">
          <li class="nav-item">
            <router-link style="color: black" class="nav-link active" :to="{ name: 'home' }"
              ><i class="fa fa-bed me-2" aria-hidden="true" style="color: black"></i
              >Stays</router-link
            >
          </li>
          <li class="nav-item">
            <router-link style="color: black" class="nav-link" :to="{ name: 'home' }"
              ><i class="fa fa-plane me-2" aria-hidden="true" style="color: black"></i
              >Flights</router-link
            >
          </li>
          <li class="nav-item" style="margin-left: 700px; ">
            <router-link style="color: black"  :to="{ name: 'Admin' }">
              Admin</router-link>
          </li>
          <li class="nav-item" >
            <router-link style="color: black"  :to="{ name: 'Upload' }">
              Upload</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      user: {},
      isLogged: localStorage.isLogged
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('agent'))
  }
};
</script>

<style scoped>
.navbar {
  background-color: white;
  color: black;
  /* padding: 20px 50px;
  margin-left: 30px; */

}
.navbar-dark .navbar-brand {
  color: black;
}
.navbar-brand {
  width: 150px;
}

.navbar-nav {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: black;
}

.nav-item {
  font-size: 14px;
  font-weight: 500;
  /* width: 120px; */
  padding: 0 20px;
  height: 40px;
  text-align: center;
  color: black;
}

.nav-item:first-child {
  border: 1px solid #FFC107;
  border-radius: 30px;
  background-color: whitesmoke;
  color: black;
}
.logo {
  height: 40px;
  width: 200px;
}

</style>
